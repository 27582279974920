import "./selectlanguage.scss";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo_white_horizontal.png";
import plFlag from "../../../Assets/Images/plFlag.png";
import enFlag from "../../../Assets/Images/enFlag.png";

export default function SelectLanguage() {
    const navigate = useNavigate();

    const changeToPL = () => {
        cookie.remove("language");
        cookie.save("language", "pl", { path: "/" });
        navigate("/news");
        navigate(0);
    };

    const changeToEn = () => {
        cookie.remove("language");
        cookie.save("language", "en", { path: "/" });
        navigate("/news");
        navigate(0);
    };

    return (
        <div className="selectLanguage">
            <div className="selectLanguage_title">
                Bella Mare FCI - Labrador Retriever
            </div>
            <div className="selectLanguage_logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="selectLanguage_container">
                <div className="selectLanguage_button" onClick={changeToPL}>
                    <img src={plFlag} alt="Poland flag" /> Polski{" "}
                    <img src={plFlag} alt="Poland flag" />
                </div>
                <div className="selectLanguage_button" onClick={changeToEn}>
                    <img src={enFlag} alt="Poland flag" /> English{" "}
                    <img src={enFlag} alt="Poland flag" />
                </div>
            </div>
        </div>
    );
}
