import { useEffect, useState } from "react";
import "./dogs.scss";
import axios from "axios";
import DogCard from "../../Cards/Dog/DogCard";
import { Language } from "../../../Utils/language";
import Loading from "../../Cards/Loading/Loading";

export default function Dogs() {
    const [data, setData] = useState([]);
    const [memory, setMemory] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs`
                );

                window.scrollTo({
                    top: window.document.body.clientWidth <= 1200 ? 0 : 400,
                    left: 0,
                    behavior: "smooth",
                });

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, [memory]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="dogs">
            <div className="dogs_title">{Language.dogs_title}</div>
            {data &&
            data.filter((f) => f.category === "males" && f.gender === "male")
                .length > 0 ? (
                <div className="dogs_description">{Language.dogs_coop}</div>
            ) : (
                ""
            )}
            <div className="dogs_container">
                {data &&
                data.filter(
                    (f) => f.category === "males" && f.gender === "male"
                ).length > 0
                    ? !memory
                        ? data
                              .filter(
                                  (f) =>
                                      f.category === "males" &&
                                      f.gender === "male"
                              )
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                        : data
                              .filter(
                                  (f) =>
                                      f.category === "memory" &&
                                      f.gender === "male"
                              )
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                    : Language.noDogs}
            </div>

            {data &&
            !memory &&
            data.filter((f) => f.gender === "male" && f.category === "semen")
                .length > 0 ? (
                <>
                    <div className="dogs_title bigMargin">
                        {Language.frozen_semen}
                    </div>
                    <div className="dogs_container">
                        {data
                            .filter(
                                (f) =>
                                    f.gender === "male" &&
                                    f.category === "semen"
                            )
                            .map((dog) => (
                                <DogCard dog={dog} key={dog.id} />
                            ))}
                    </div>
                </>
            ) : (
                ""
            )}

            {data &&
            !memory &&
            data.filter((f) => f.gender === "male" && f.category === "memory")
                .length > 0 ? (
                <div className="dogs_memory" onClick={() => setMemory(!memory)}>
                    {Language.males_memory_title}
                </div>
            ) : data.filter(
                  (f) => f.gender === "male" && f.category === "memory"
              ).length > 0 ? (
                <div className="dogs_memory" onClick={() => setMemory(!memory)}>
                    {Language.back_title}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
