import { useEffect, useState } from "react";
import "./pedigreetree.scss";
import axios from "axios";

export default function PedigreeTree({ pedigreedata }) {
    const [data, setData] = useState([]);
    const [width, setWidth] = useState(window.document.body.clientWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.document.body.clientWidth);
        });
        return window.removeEventListener("resize", () => {
            setWidth(window.document.body.clientWidth);
        });
    }, [width]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs`
                );

                setData(results.data);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, []);

    const searchDog = (dog) => {
        if (!dog) {
            return false;
        }

        const psy = data.filter((f) =>
            dog.toLowerCase().includes(f.name.toLowerCase())
        );

        if (psy && psy.length > 0) {
            if (dog.includes("Champion")) {
                return (
                    <>
                        Champion <br />
                        <a
                            href={`https://bellamare.pl/dogs/${psy[0].id}`}
                            target="__blank"
                        >
                            {psy[0].name}
                        </a>
                    </>
                );
            } else {
                return (
                    <a
                        href={`https://bellamare.pl/dogs/${psy[0].id}`}
                        target="__blank"
                    >
                        {psy[0].name}
                    </a>
                );
            }
        } else {
            const str = dog;
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: str,
                    }}
                ></div>
            );
        }
    };

    return pedigreedata ? (
        <>
            <table className="pedigreeTree">
                <thead>
                    <tr>
                        {/* Father */}
                        <th className="tg-xwyw" rowSpan="4">
                            {searchDog(pedigreedata.father.name)}
                        </th>

                        {/* GrandFather */}
                        <th className="tg-xwyw" rowSpan="2">
                            {searchDog(pedigreedata.father.grandfather.name)}
                        </th>

                        {/* GreatGrandFather */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.father.grandmother.greatgrandfather
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GreatGrandMother */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.father.grandmother.greatgrandmother
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GrandMother */}
                        <th className="tg-xwyw" rowSpan="2">
                            {searchDog(pedigreedata.father.grandmother.name)}
                        </th>
                        {/* GreatGrandFather */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.father.grandfather.greatgrandfather
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GreatGrandMother */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.father.grandfather.greatgrandmother
                                    .name
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* Mother */}
                        <th className="tg-xwyw" rowSpan="4">
                            {searchDog(pedigreedata.mother.name)}
                        </th>

                        {/* GrandFather */}
                        <th className="tg-xwyw" rowSpan="2">
                            {searchDog(pedigreedata.mother.grandfather.name)}
                        </th>

                        {/* GreatGrandFather */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.mother.grandmother.greatgrandfather
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GreatGrandMother */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.mother.grandmother.greatgrandmother
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GrandMother */}
                        <th className="tg-xwyw" rowSpan="2">
                            {searchDog(pedigreedata.mother.grandmother.name)}
                        </th>
                        {/* GreatGrandFather */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.mother.grandfather.greatgrandfather
                                    .name
                            )}
                        </th>
                    </tr>
                    <tr>
                        {/* GreatGrandMother */}
                        <th className="tg-xwyw">
                            {searchDog(
                                pedigreedata.mother.grandfather.greatgrandmother
                                    .name
                            )}
                        </th>
                    </tr>
                </tbody>
            </table>
        </>
    ) : (
        ""
    );
}

/* <>
                <div className="pedigree_title">{Language.father_title}</div>
                <table className="pedigreeTree_mobile">
                    <thead>
                        <tr>
                            <th colspan="4">
                                {searchDog(pedigreedata.father.name)}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2">
                                {searchDog(
                                    pedigreedata.father.grandfather.name
                                )}
                            </td>
                            <td colspan="2">
                                {searchDog(
                                    pedigreedata.father.grandmother.name
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {searchDog(
                                    pedigreedata.father.grandfather
                                        .greatgrandfather.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.father.grandfather
                                        .greatgrandmother.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.father.grandmother
                                        .greatgrandfather.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.father.grandmother
                                        .greatgrandmother.name
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="pedigree_title">{Language.mother_title}</div>
                <table className="pedigreeTree_mobile">
                    <thead>
                        <tr>
                            <th colspan="4">
                                {searchDog(pedigreedata.mother.name)}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2">
                                {searchDog(
                                    pedigreedata.mother.grandfather.name
                                )}
                            </td>
                            <td colspan="2">
                                {searchDog(
                                    pedigreedata.mother.grandmother.name
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {searchDog(
                                    pedigreedata.mother.grandfather
                                        .greatgrandfather.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.mother.grandfather
                                        .greatgrandmother.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.mother.grandmother
                                        .greatgrandfather.name
                                )}
                            </td>
                            <td>
                                {searchDog(
                                    pedigreedata.mother.grandmother
                                        .greatgrandmother.name
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </> */
