import { useEffect, useState } from "react";
import "./litters.scss";
import axios from "axios";
import { useSearchParams, redirect, useNavigate } from "react-router-dom";
import { Language } from "../../../../Utils/language";
import LitterCard from "../../../Cards/Litter/LitterCard";
import Loading from "../../../Cards/Loading/Loading";

export default function Litters() {
    const [data, setData] = useState([]);
    const [searchParams, setsearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const type = searchParams.get("type");
    const navigate = useNavigate();

    useEffect(() => {
        if (type !== "current" && type !== "planned" && type !== "archive") {
            navigate("/litters?type=current");
        } else {
            setLoading(true);
            const fetchData = async () => {
                try {
                    const results = await axios.get(
                        `https://api.bellamare.pl/litters`
                    );

                    setData(results.data);
                    setLoading(false);
                } catch (error) {
                    return "error";
                }
            };

            fetchData();
        }
    }, [type, navigate]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="litters">
            <div className="litters_title">
                {type && type === "planned"
                    ? Language.planned_litters_title
                    : Language.current_litters_title}
            </div>
            <div className="litters_container">
                {data && data.filter((f) => f.type === type).length > 0
                    ? data
                          .filter((f) => f.type === type)
                          .map((litter) => (
                              <LitterCard litter={litter} key={litter.id} />
                          ))
                    : "Aktualnie nie ma żadnego miotu."}
            </div>
        </div>
    );
}
