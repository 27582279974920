import { useNavigate, useParams } from "react-router-dom";
import "./dogsdetails.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import cookie from "react-cookies";
import setPathtoImage from "../../../../Utils/setPathtoImage";
import { Language } from "../../../../Utils/language";
import PedigreeTree from "../../../Cards/PedigreeTree/PedigreeTree";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import Loading from "../../../Cards/Loading/Loading";

export default function DogsDetails() {
    const params = useParams();
    const id = params.id;
    const lang = cookie.load("language");

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs/${id}`
                );

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return navigate(-1);
            }
        };

        fetchData();
    }, [id, navigate]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="dogsDetails">
            <div className="dogsDetails_title">{data.name}</div>

            {data.birthDate && (
                <div className="dogsDetails_birth">
                    {Language.birthDate_title}
                    {new Date(data.birthDate).toLocaleDateString()}
                </div>
            )}

            <div className="dogsDetails_mainImg">
                <LightgalleryProvider
                    lightgallerySettings={{
                        download: false,
                    }}
                >
                    {data.images &&
                        data.images
                            .filter((f) => f.field === "mainImg")
                            .map((image) => (
                                <>
                                    <LightgalleryItem
                                        src={setPathtoImage(image.path)}
                                        key={image.id}
                                    >
                                        <img
                                            src={setPathtoImage(image.path)}
                                            alt=""
                                        />
                                    </LightgalleryItem>
                                </>
                            ))}
                </LightgalleryProvider>
            </div>
            <div
                className="dogsDetails_description"
                dangerouslySetInnerHTML={{
                    __html:
                        lang === "pl"
                            ? data.description_pl
                            : data.description_en,
                }}
            ></div>
            <div className="dogsDetails_pedigree">
                <div className="dogsDetails_title">
                    {Language.pedigree_title}
                </div>

                <PedigreeTree pedigreedata={data.pedigree} />
            </div>
            <div className="dogsDetails_gallery">
                <div className="dogsDetails_title">
                    {data.images &&
                    data.images.filter((f) => f.field === "gallery").length > 0
                        ? Language.gallery_title
                        : ""}
                </div>
                <div className="dogsDetails_gallery__items">
                    <LightgalleryProvider
                        lightgallerySettings={{
                            download: false,
                        }}
                    >
                        {data.images &&
                        data.images.filter((f) => f.field === "gallery")
                            .length > 0
                            ? data.images
                                  .filter((f) => f.field === "gallery")
                                  .map((image) => (
                                      <div className="dogsDetails_gallery__image">
                                          <LightgalleryItem
                                              src={setPathtoImage(image.path)}
                                              key={image.id}
                                          >
                                              <img
                                                  src={setPathtoImage(
                                                      image.pathThumb
                                                  )}
                                                  alt=""
                                              />
                                          </LightgalleryItem>
                                          <div>{image.title}</div>
                                      </div>
                                  ))
                            : ""}
                    </LightgalleryProvider>
                </div>
            </div>
        </div>
    );
}
