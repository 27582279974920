import footerBackground from "../../Assets/Images/footer.jpg";
import "./footer.scss";

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <img src={footerBackground} alt="footer" draggable="false" />
            </footer>
        </>
    );
}
