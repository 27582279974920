import "./contact.scss";
import logo from "../../../Assets/Images/logo_white_horizontal.png";
import Contact1Image from "../../../Assets/Images/Contact/gallery1.jpg";
import Contact2Image from "../../../Assets/Images/Contact/gallery2.jpg";
import { Language } from "../../../Utils/language";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";

export default function Contact() {
    return (
        <div className="contact">
            <div className="contact_title">{Language.contact_title}</div>
            <div className="contact_logo">
                <img
                    src={logo}
                    alt="Logo Bella Mare FCI Kennel"
                    draggable="false"
                />
            </div>
            <div className="contact_data">{Language.contact_data}</div>
            <div className="contact_gallery">
                <LightgalleryProvider
                    lightgallerySettings={{
                        download: false,
                    }}
                >
                    <LightgalleryItem src={Contact1Image}>
                        <img src={Contact1Image} alt="" />
                    </LightgalleryItem>
                    <LightgalleryItem src={Contact2Image}>
                        <img src={Contact2Image} alt="" />
                    </LightgalleryItem>
                </LightgalleryProvider>
            </div>
        </div>
    );
}
