import setPathtoImage from "../../../Utils/setPathtoImage";
import "./littercard.scss";
import cookie from "react-cookies";

export default function LitterCard({ litter }) {
    const lang = cookie.load("language");
    return (
        <div className="litter">
            <div className="litter_title">
                {lang === "pl" ? litter.name_pl : litter.name_en}
            </div>
            <div
                className="litter_description"
                dangerouslySetInnerHTML={{
                    __html:
                        lang === "pl"
                            ? litter.description_pl
                            : litter.description_en,
                }}
            ></div>
            {litter.images.length > 0 ? (
                <div className="litter_gallery">
                    {litter.images.map((image) => (
                        <img
                            src={setPathtoImage(image.pathThumb)}
                            alt=""
                            key={image.id}
                        />
                    ))}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
