import headerBackground from "../../Assets/Images/header.jpg";
import "./header.scss";

export default function Header() {
    return (
        <header className="header">
            <img
                src={headerBackground}
                alt="header"
                className="header_image"
                draggable="false"
            />
        </header>
    );
}
