import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import "./newscard.scss";
import cookie from "react-cookies";
import setPathtoImage from "../../../Utils/setPathtoImage";
import { useEffect, useState } from "react";
import axios from "axios";

export default function NewsCard({ news }) {
    const [dogs, setDogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs`
                );

                setDogs(results.data);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    });

    const lang = cookie.load("language");

    const replaceDogNamesWithLinks = (description) => {
        // Mapowanie nazw psów na linki
        const dogLinks = dogs.map((dog) => ({
            name: dog.name.toLowerCase(),
            link: `/dogs/${dog.id}`,
        }));

        // Zamiana nazw psów na linki w opisie
        let replacedDescription = description;
        dogLinks.forEach(({ name, link }) => {
            //const regex = new RegExp(`\\b${name}\\b`, "gi"); // Użyj wyrażenia regularnego z granicami słów
            const startIndex = replacedDescription.toLowerCase().search(name); // Sprawdź, czy nazwa psa znajduje się w opisie
            if (startIndex !== -1) {
                const endIndex = startIndex + name.length;
                const matchedWord = replacedDescription.slice(
                    startIndex,
                    endIndex
                );
                replacedDescription = replacedDescription.replace(
                    matchedWord,
                    `<a href="${link}">${matchedWord}</a>`
                );
            }
        });

        return replacedDescription;
    };

    const getDescription = () => {
        const description =
            lang === "pl" ? news.description_pl : news.description_en;
        return { __html: replaceDogNamesWithLinks(description) };
    };

    return (
        <div className="news_container__item">
            <div className="addedAt">
                {new Date(news.addedAt).toLocaleDateString()}
            </div>
            <div className="title">
                {lang === "pl" ? news.title_pl : news.title_en}
            </div>
            <div
                className="description"
                dangerouslySetInnerHTML={getDescription()}
            ></div>
            {news.images.length ? (
                <div className="gallery">
                    <LightgalleryProvider
                        lightgallerySettings={{
                            download: false,
                        }}
                    >
                        {news.images.map((image) => (
                            <LightgalleryItem
                                src={setPathtoImage(image.path)}
                                key={image.id}
                            >
                                <img
                                    src={setPathtoImage(image.pathThumb)}
                                    alt=""
                                />
                            </LightgalleryItem>
                        ))}
                    </LightgalleryProvider>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
