import { useEffect, useState } from "react";
import "./youngsters.scss";
import axios from "axios";
import DogCard from "../../Cards/Dog/DogCard";
import { Language } from "../../../Utils/language";
import Loading from "../../Cards/Loading/Loading";

export default function Youngsters() {
    const [data, setData] = useState([]);
    const [memory, setMemory] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs`
                );

                window.scrollTo({
                    top: window.document.body.clientWidth <= 1200 ? 0 : 400,
                    left: 0,
                    behavior: "smooth",
                });

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "Error";
            }
        };

        fetchData();
    }, [memory]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="youngsters">
            <div className="youngsters_title">{Language.youngsters_title}</div>

            <div className="youngsters_container">
                {data &&
                data.filter(
                    (f) =>
                        f.category === "youngsters" ||
                        f.category === "bredyouth"
                ).length > 0
                    ? !memory
                        ? data
                              .filter((f) => f.category === "youngsters")
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                        : data
                              .filter((f) => f.category === "bredyouth")
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                    : "Brak Młodzieży w bazie danych!"}
            </div>

            {data &&
            !memory &&
            data.filter((f) => f.category === "bredyouth").length > 0 ? (
                <div
                    className="youngsters_memory"
                    onClick={() => setMemory(!memory)}
                >
                    {Language.youngsters_memory_title}
                </div>
            ) : data.filter((f) => f.category === "bredyouth").length > 0 ? (
                <div
                    className="youngsters_memory"
                    onClick={() => setMemory(!memory)}
                >
                    {Language.back_title}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
