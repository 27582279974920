import { useEffect, useState } from "react";
import "./aboutus.scss";
import axios from "axios";
import { Language } from "../../../Utils/language";
import cookie, { load } from "react-cookies";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import setPathtoImage from "../../../Utils/setPathtoImage";
import Loading from "../../Cards/Loading/Loading";

export default function AboutUs() {
    const [data, setData] = useState([]);
    const lang = cookie.load("language");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/aboutus`
                );

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="aboutus">
            <div className="aboutus_title">{Language.aboutus_title}</div>
            {data && data.length > 0 ? (
                <div className="aboutus_container">
                    {data.map((about) => (
                        <div className="aboutusItem" key={about.id}>
                            <div className="aboutusItem_title"></div>
                            <div
                                className={`aboutusItem_container ${
                                    about.images.length >= 2 ? "column" : ""
                                }`}
                            >
                                <div
                                    className={`aboutusItem_description ${
                                        about.images.length === 0 &&
                                        about.images.length >= 2
                                            ? ""
                                            : "full"
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            lang === "pl"
                                                ? about.description_pl
                                                : about.description_en,
                                    }}
                                ></div>
                                {about.images && about.images.length > 0 ? (
                                    <div
                                        className={`aboutusItem_gallery ${
                                            about.images.length >= 2
                                                ? "full small"
                                                : ""
                                        }`}
                                    >
                                        <LightgalleryProvider
                                            lightgallerySettings={{
                                                download: false,
                                            }}
                                        >
                                            {about.images.map((image) => (
                                                <LightgalleryItem
                                                    src={setPathtoImage(
                                                        image.path
                                                    )}
                                                    key={image.id}
                                                >
                                                    <img
                                                        src={setPathtoImage(
                                                            image.pathThumb
                                                        )}
                                                        alt=""
                                                    />
                                                </LightgalleryItem>
                                            ))}
                                        </LightgalleryProvider>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                Language.notInfo_Aboutus
            )}
        </div>
    );
}
