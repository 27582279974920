import { useEffect, useState } from "react";
import "./females.scss";
import axios from "axios";
import DogCard from "../../Cards/Dog/DogCard";
import { Language } from "../../../Utils/language";
import Loading from "../../Cards/Loading/Loading";

export default function Females() {
    const [data, setData] = useState([]);
    const [memory, setMemory] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/dogs`
                );

                window.scrollTo({
                    top: window.document.body.clientWidth <= 1200 ? 0 : 400,
                    left: 0,
                    behavior: "smooth",
                });

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, [memory]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="females">
            <div className="females_title">{Language.females_title}</div>
            {data &&
            data.filter(
                (f) => f.category === "females" && f.gender === "female"
            ).length > 0 ? (
                <div className="females_description">
                    <p>{Language.females_coop}</p>
                </div>
            ) : (
                ""
            )}
            <div className="females_container">
                {data &&
                data.filter(
                    (f) => f.category === "females" && f.gender === "female"
                ).length > 0
                    ? !memory
                        ? data
                              .filter(
                                  (f) =>
                                      f.category === "females" &&
                                      f.gender === "female"
                              )
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                        : data
                              .filter(
                                  (f) =>
                                      f.category === "memory" &&
                                      f.gender === "female"
                              )
                              .map((dog) => <DogCard dog={dog} key={dog.id} />)
                    : "Brak Suczek w bazie danych!"}
            </div>

            {data &&
            !memory &&
            data.filter((f) => f.gender === "female" && f.category === "memory")
                .length > 0 ? (
                <div
                    className="females_memory"
                    onClick={() => setMemory(!memory)}
                >
                    {Language.females_memory_title}
                </div>
            ) : data.filter(
                  (f) => f.gender === "female" && f.category === "memory"
              ).length > 0 ? (
                <div
                    className="females_memory"
                    onClick={() => {
                        setMemory(!memory);
                    }}
                >
                    {Language.back_title}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
