import { Outlet } from "react-router-dom";
import Header from "./Components/Header/header";
import Sidebar from "./Components/Sidebar/Sidebar";
import Footer from "./Components/Footer/footer";

export default function App() {
    return (
        <>
            <Header />
            <div className="Layout">
                <div className="Layout_sidebar">
                    <Sidebar />
                </div>
                <div className="Layout_content">
                    <div>
                        <Outlet />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
