import { Link } from "react-router-dom";
import "./dogcard.scss";
import setPathtoImage from "../../../Utils/setPathtoImage";

export default function DogCard({ dog }) {
    return (
        <div className="dogCard">
            <Link to={`https://bellamare.pl/dogs/${dog.id}`}>
                <div className="dogCard_mainImg">
                    <img
                        src={setPathtoImage(
                            dog.images.filter((f) => f.field === "mainImg")[0]
                                .pathThumb
                        )}
                        alt=""
                    />
                </div>
                <div className="dogCard_name">{dog.name}</div>
            </Link>
        </div>
    );
}
