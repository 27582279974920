import { useEffect, useState } from "react";
import { Language } from "../../../../Utils/language";
import "./puppiesmainpage.scss";
import axios from "axios";
import cookies from "react-cookies";
import { Link } from "react-router-dom";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import setPathtoImage from "../../../../Utils/setPathtoImage";
export default function PuppiesMainPage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const lang = cookies.load("language");

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const results = await axios.get(
                    `https://api.bellamare.pl/puppies`
                );

                setData(results.data);
                setLoading(false);
            } catch (error) {
                return "error";
            }
        };

        fetchData();
    }, []);

    return (
        <div className="puppiesMainPage">
            <div className="puppiesMainPage_title">
                {Language.puppies_title}
            </div>
            <div className="puppiesMainPage_container">
                <Link
                    to="/litters?type=current"
                    className="puppiesMainPage_container__button"
                >
                    {Language.current_litters_title}
                </Link>
                <Link
                    to="/litters?type=planned"
                    className="puppiesMainPage_container__button"
                >
                    {Language.planned_litters_title}
                </Link>
            </div>

            {data && data.length > 0 ? (
                <>
                    <div className="puppiesMainPage_title puppiesBigMargin">
                        {Language.puppies_why_title}
                    </div>

                    <div className="puppiesMainPage_why_container">
                        {data && data.length > 0
                            ? data.map((puppy) => (
                                  <div
                                      className="puppy_container"
                                      key={puppy.id}
                                  >
                                      <div className="puppy_title">
                                          {lang === "pl"
                                              ? puppy.title_pl
                                              : puppy.title_en}
                                      </div>
                                      <div
                                          className="puppy_description"
                                          dangerouslySetInnerHTML={{
                                              __html:
                                                  lang === "pl"
                                                      ? puppy.description_pl
                                                      : puppy.description_en,
                                          }}
                                      ></div>
                                      <div className="puppy_gallery">
                                          {puppy.images.length > 0 ? (
                                              <LightgalleryProvider
                                                  lightgallerySettings={{
                                                      download: false,
                                                  }}
                                              >
                                                  {puppy.images.map((image) => (
                                                      <div>
                                                          <LightgalleryItem
                                                              src={setPathtoImage(
                                                                  image.path
                                                              )}
                                                              key={image.map}
                                                          >
                                                              <img
                                                                  src={setPathtoImage(
                                                                      image.pathThumb
                                                                  )}
                                                                  alt=""
                                                              />
                                                          </LightgalleryItem>
                                                          <div>
                                                              {image.title}
                                                          </div>
                                                      </div>
                                                  ))}
                                              </LightgalleryProvider>
                                          ) : (
                                              ""
                                          )}
                                      </div>
                                  </div>
                              ))
                            : ""}
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
}
