import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import { Language } from "../../Utils/language";
import { useEffect, useState } from "react";
import hamburger_menu from "../../Assets/Images/hamburger_menu.svg";
import close_menu from "../../Assets/Images/close_menu.svg";
import cookies from "react-cookies";

export default function Sidebar() {
    const [width, setWidth] = useState(window.document.body.clientWidth);

    const lang = cookies.load("language");

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.document.body.clientWidth);
        });
        return window.removeEventListener("resize", () => {
            setWidth(window.document.body.clientWidth);
        });
    }, [width]);

    const [active, setActive] = useState(false);

    const handleActiveClick = () => {
        window.scrollTo({
            top: width <= 1200 ? 0 : 400,
            left: 0,
            behavior: "smooth",
        });

        setActive(!active);
    };

    return (
        <>
            <div
                className={`${
                    width <= 1200 ? "mobile_hamburger__show" : ""
                } mobile_hamburger`}
                onClick={handleActiveClick}
            >
                <img src={active ? close_menu : hamburger_menu} alt="" />
            </div>

            <sidebar
                className={`${width <= 1200 ? "mobile" : ""} ${
                    active ? "active" : ""
                } sidebar`}
            >
                <div className="sidebar_links">
                    <NavLink
                        to="/"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.home_title}
                    </NavLink>
                    <NavLink
                        to="/news"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.news_title}
                    </NavLink>
                    <NavLink
                        to="/males"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.dogs_title}
                    </NavLink>
                    <NavLink
                        to="females"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.females_title}
                    </NavLink>
                    <NavLink
                        to="/youngsters"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.youngsters_title}
                    </NavLink>
                    <NavLink
                        to="/puppies"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.puppies_title}
                    </NavLink>
                    <NavLink
                        to="/aboutus"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.aboutus_title}
                    </NavLink>

                    {lang === "en" ? (
                        ""
                    ) : (
                        <NavLink
                            to="/aboutbreed"
                            className={({ isActive }) => {
                                return isActive
                                    ? "sidebar_link__active sidebar_link"
                                    : "sidebar_link";
                            }}
                            onClick={handleActiveClick}
                        >
                            {Language.aboutbreed_title}
                        </NavLink>
                    )}

                    <NavLink
                        to="/contact"
                        className={({ isActive }) => {
                            return isActive
                                ? "sidebar_link__active sidebar_link"
                                : "sidebar_link";
                        }}
                        onClick={handleActiveClick}
                    >
                        {Language.contact_title}
                    </NavLink>
                </div>
            </sidebar>
        </>
    );
}
