import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import {
    Navigate,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import Contact from "./Components/Views/Contact/Contact";
import News from "./Components/Views/News/News";
import Dogs from "./Components/Views/Dogs/Dogs";
import Females from "./Components/Views/Females/Females";
import Youngsters from "./Components/Views/Youngsters/Youngsters";
import PuppiesMainPage from "./Components/Views/Puppies/PuppiesMainPage/PuppiesMainPage";
import Litters from "./Components/Views/Puppies/Litters/Litters";
import AboutUs from "./Components/Views/AboutUs/AboutUs";
import AboutBreed from "./Components/Views/AboutBreed/AboutBreed";
import DogsDetails from "./Components/Views/Dogs/DogsDetails/DogsDetails";
import SelectLanguage from "./Components/Views/SelectLanguage/SelectLanguage";

import "lightgallery.js/dist/css/lightgallery.css";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path="/" element={<SelectLanguage />} />
            <Route path="/news" element={<News />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/aboutbreed" element={<AboutBreed />} />
            <Route path="/males" element={<Dogs />} />

            <Route exact path="/dogs/:id" element={<DogsDetails />} />
            <Route path="/females" element={<Females />} />

            <Route path="/youngsters" element={<Youngsters />}></Route>
            <Route path="/puppies" element={<PuppiesMainPage />} />
            <Route path="/litters" element={<Litters />} />
            <Route path="/contact" element={<Contact />} />
        </Route>
    )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
